import "./FintechConnector.scss";
import React from "react";

import Messages from "../../assets/case-messages.png";
import PhoneHalf from "../../assets/case-fintech-hero-phone.png";
import Profile from "../../assets/case-profile.png";
import Threads from "../../assets/case-threads.png";
import Dashboard from "../../assets/case-dashboard.png";
import List from "../../assets/case-list.png";

//icons
import UserIcon from "../../assets/icons/user.svg";
import UsersIcon from "../../assets/icons/users.svg";
import ChatIcon from "../../assets/icons/chat-1.svg";
import ChatIcon2 from "../../assets/icons/chat.svg";
import CloudIcon from "../../assets/icons/cloud-computing-1.svg";
import Computer from "../../assets/Computer-illustration.svg";
import DownloadIcon from "../../assets/icons/download.svg";
import EarthIcon from "../../assets/icons/planet-earth.svg";
import FileIcon from "../../assets/icons/file-2.svg";
import FileIcon2 from "../../assets/icons/file-1.svg";
import FilterIcon from "../../assets/icons/filter.svg";
import FlagIcon from "../../assets/icons/flag.svg";
import FolderIcon from "../../assets/icons/folder-1.svg";
import IdIcon from "../../assets/icons/id-card.svg";
import LayerIcon from "../../assets/icons/layers.svg";
import LoupeIcon from "../../assets/icons/loupe.svg";
import MessageIcon from "../../assets/icons/message.svg";
import MessagePreviewIcon from "../../assets/icons/message-1.svg";
import Offer1 from "../../assets/offer1.svg";
import Offer3 from "../../assets/offer3.svg";
import PhoneIcon from "../../assets/icons/smartphone.svg";
import Shape from "../../assets/case-fintech-shape.svg";


const showcaseItems = [
  {
    h3: "Dashboard",
    p: [
      "Dashboard view allows users to have a quick view on the latest updates. Users can scroll through the newest community members who have joined the platform or check how many new unread messages they have in the inbox.",
      "It also provides a quick search functionality when someone is looking for a specific community member.",
    ],
    li: [
      "New members who have joined the platform",
      "Recent messages",
      "Quick members search access",
    ],
    icon: [UserIcon, ChatIcon, LoupeIcon],
    img: Dashboard,
  },
  {
    h3: "Member lists",
    p: [
      "Effortless search for members or business opportunities - an ultimate timesaver for busy professionals. Set relevant filters and find people in your location who are interested in specific fintech initiatives.",
    ],
    li: [
      "Quick user search option",
      "Advanced filtering tool",
      "Glimpse user details view",
    ],
    icon: [UsersIcon, FilterIcon, IdIcon],
    img: List,
  },
  {
    h3: "Messages",
    p: [
      "Scroll through all historical messages or create new message threads in a flash.",
    ],
    li: [
      "Easy way to create peer to peer or group chats",
      "Thread participants preview",
      "Message preview",
      "New message indicators",
    ],
    icon: [ChatIcon2, FileIcon, MessagePreviewIcon, DownloadIcon],
    img: Messages,
  },
  {
    h3: "Threads",
    p: [
      "Message threads with real-time messaging designed for easy and efficient communication. Add new members to the communication anytime you need it. Easy access to the member profiles helps maximise business opportunities from each conversation.",
    ],
    li: [
      "Real-time messaging",
      "Unread message indicator",
      "New message notifications",
      "Easy access to all member profile views",
    ],
    icon: [PhoneIcon, MessageIcon, CloudIcon, FileIcon2],
    img: Threads,
  },
  {
    h3: "Profile",
    p: [
      "Profile view provides detailed information about each community member, including: Professional information, Fintech ecosystem, Areas of business interests",
      "For maximum transparency, some of the sections are displayed as pills and tags, which helps highlight the key information.",
    ],
    li: [
      "Clear structure",
      "Well organised user details",
      "Pills and tags labeling",
      "Linkedin and Twitter accounts",
    ],
    icon: [LayerIcon, FolderIcon, FlagIcon, EarthIcon],
    img: Profile,
  },
];
function FintechConnector() {
  return (
    <>
      <div className="hero-background" />
      <div className="sections fintech-connector">
        <section className="hero">
          <div className="hero__box">
            <div>
              <h1>Fintech connector</h1>
              <h2>Our contribution to the Fintech World</h2>
              <span>
                FinTech Connector is a rapidly growing community that requires
                new tools to support its members in exploring new fintech
                opportunities.
              </span>
            </div>
            <img src={PhoneHalf} alt="" />
          </div>
        </section>
        <section className="section-features">
          <div className="features">
            <div>
              <span>Member profiles</span>
            </div>
            <div>
              <span>Real-time messaging</span>
            </div>
            <div>
              <span>Invite your colleagues</span>
            </div>
            <div>
              <span>NEW MESSAGE NOTIFICATIONS</span>
            </div>
            <div>
              <span>member notifications</span>
            </div>
            <div>
              <span>Android & iOS</span>
            </div>
          </div>
        </section>
        <section className="section-details">
          <div className="details">
            <h2>FinTech Connector in details</h2>
            <p>
              FinTech Connector is an exclusive and virtual professional
              membership network that connects fintech start-ups and
              entrepreneurs with global professionals, organizations, and
              investors to collaborate on fintech opportunities around the
              world. FinTech Connector was established in 2017 and now has
              thousands of members in over 100 countries.
              </p>
            <p>
              The rapidly growing community requires new tools to effectively
              connect with members, explore new business opportunities, and
                collaborate on innovative fintech initiatives.{" "}
            </p>
            <p>
              FinTech Connector's mobile app is your day-to-day partner for
              fintech innovation.
              </p>
            <div className="details__numbers">
              <div>
                <span>2700</span>
                <span>members</span>
              </div>
              <div>
                <span>250</span>
                <span>new members every month</span>
              </div>
              <div>
                <span>50.000</span>
                <span>messages every month</span>
              </div>
            </div>
          </div>
        </section>
        <section className="section-challanges">
          <div className="challanges">
            <h2>Our Challenges</h2>
            <p>
              While designing the mobile app for FinTech Connector, our key
              goal was to come up with solutions to match the following
              challenges:
              </p>
            <div className="challanges__box">
              <h3>Challenge</h3>
              <Offer1 alt="" />
              <ul>
                <li>1. How to process thousands of real time messages?</li>
                <li>
                  2. How to create a scalable architecture for future growth?
                  </li>
                <li>
                  3. What is the best technology for the native cross-platform
                  application?
                  </li>
                <li>
                  4. How to set-up a design process for a speedy go- tomarket
                  app?
                  </li>
              </ul>
            </div>
            <div className="challanges__box">
              <h3>Our solution</h3>
              <Offer3 alt="" />
              <ul>
                <li>1. Robust Technologies - Rails/ Firebase</li>
                <li>2. Split the application into independent modules</li>
                <li>3. Flutter, highly performing and efficient to code</li>
                <li>4. Quick iterations design, ongoing early feedback</li>
              </ul>
            </div>
            <div className="challanges__blue-bg">
              <div>
                {showcaseItems.map((item, index) => {
                  return <img src={item.img} alt="" />;
                })}
                <img src={showcaseItems[4].img} alt="" />
              </div>
            </div>
            <p>
              Working together with Angel Lorente, CEO of FinTech Connector,
              we set an ambitious goal to design and deliver the application
              in an impressive 3 months! To this end, we implemented some
              tools and processes to facilitate the collaboration.
              </p>
            <ol>
              <li>
                Everyday communication with the entire team, to come up with a
                clear set of requirements and priorities. We used such tools
                as Slack, Whatsapp and conference calls
                </li>
              <li>
                Instant UX/ UI design and minimising the time needed for
                revisions;
                </li>
              <li>
                A quick prototype of the mobile app to verify the look and
                feel of the app and test the assumptions
                </li>
              <li>
                Collaboration with the community during friendly user tests to
                ensure quick response and swift implementation of adjustments
                and improvements.
                </li>
            </ol>
            <p>
              Cross-platform helped us reduce the time needed to complete the
              project. The technology of our choice was Flutter. This
              cross-platform language is used to build and maintain
              top-quality Android and iOS applications in a quick and
              cost-effective manner.
              </p>
          </div>
        </section>
        <section className="section-testimonial">
          <div className="testimonial">
            <span className="testimonial__main">
              “Working with AllBright.io to create our mobile app was an
              absolute pleasure. They are professional, knowledgeable, and
              easy to work with. Thanks AllBright team!”
              </span>
            <span className="testimonial__author">Angel Lorente</span>
            <span className="testimonial__author-details">
              Founder & CEO of Fintech Connector
              </span>
          </div>
        </section>
        <section className="section-solution">
          <div className="solution">
            <h2>Bespoke Solution</h2>
            <p>
              The first iteration of the project was focused on communication
              functionality. It allows members to search for new peers and
              communicate with them easily using the built-in app messaging
              feature.
              </p>
            <div className="solution__box">
              <div>
                <Shape alt="" />
              </div>
              <div>
                <h3>system</h3>
                <span>
                  The application is available for Android and iOS users. It
                  was essential to design the product, which will be
                    transparent and easy to use on both platforms.{" "}
                </span>
              </div>
            </div>
            <p>
              This was achieved by employing a contrast between white
              background and intense color scheme, usually associated with
              business communication and professionalism.
              </p>
            <p>
              We paid attention to intuitive navigation: users should be able
              to shift easily between app screens and functions. To this end,
              an easily accessible bottom menu was implemented. Spacing
              between elements is designed to increase the visibility, while
              each component has enough room to display information which is
              necessary for the user.
              </p>
            <div className="solution__box solution__box--right">
              <div>
                <h3>communication</h3>
                <span>
                  We implemented the following functionalities to make the
                  connection and communication within the FinTech Connector
                  community easier.
                  </span>
              </div>
              <div>
                <Computer alt="" />
              </div>
            </div>
          </div>
        </section>
        <section>
          {showcaseItems.map((item, index) => {
            return (
              <div className="showcase" key={index}>
                <div className="showcase__column showcase__column--left">
                  <img src={item.img} alt="" />
                </div>
                <div className="showcase__column showcase__column--right">
                  <h3>{item.h3}</h3>
                  {item.p.map((paragraph, index) => {
                    return <p key={`p-${index}`}>{paragraph}</p>;
                  })}
                  <h4>MAIN FEATURES</h4>
                  <ul>
                    {item.li.map((listItem, index) => {
                      const Icon = item.icon[index]
                      return (
                        <li key={`i-${index}`}>
                          <Icon
                            className="list-icon"
                            alt="screen"
                          />
                          {listItem}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            );
          })}
        </section>
      </div>
    </>
  );
}

export default FintechConnector;
