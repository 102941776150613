import React, { ReactElement } from 'react'
import FintechConnector from "../../../components/FintechConnector/FintechConnector"
import Seo from "../../../components/Seo/seo"



export default function fintechConnrctor(): ReactElement {
    return (
        <div>
            <Seo title={"Fintech Connector"}/>
            <FintechConnector />
        </div>
    )
}
